import PostService from "../../API/PostService"
import {termActions} from '../vars/term'

export const fetchTerms = () => {
	return async (dispatch) => {
		try {
			dispatch({type: termActions.fetchTerms})
			const response = await PostService.getAllTerms();
			response.data.termsWorkBook = {}
			response.data.termsBook = {}
			response.data.terms_taxonomy.forEach (tt => {
				response.data.termsWorkBook[tt.term_taxonomy_id] = Number(tt.term_id)
			});
			response.data.terms.forEach ((t,i) => {
				response.data.termsBook[t.term_id] = i
				t.posts = []
			})
			const wb = response.data.termsWorkBook;
			const b = response.data.termsBook;
			const te = response.data.terms;
			const trs = response.data.terms_relationships;
			const tx = response.data.terms_taxonomy;

			response.data.categories = {}

			tx.forEach (tt => {
				te[b[tt.term_id]]['taxonomy'] = tt.taxonomy;
				te[b[tt.term_id]]['description'] = tt.description;
				if (tt.taxonomy === 'category') {
					response.data.categories[te[b[tt.term_id]]['slug']] = {
						id:Number(tt.term_id), 
						description: tt.description,
						parent: tt.parent,
						name: te[b[tt.term_id]]['name'],
					}
				}
			})
			
			trs.forEach ( r =>  {
				te[b[wb[r.term_taxonomy_id]]]['posts'].push(Number(r.object_id))
			})

			const postToTermNumber = {}
			te.forEach (t => {
				t.term_id = Number(t.term_id)
				t.posts.forEach (pn => {
					if (postToTermNumber[pn]) {
						postToTermNumber[pn].push(b[t.term_id])
					} else {
						postToTermNumber[pn] = [b[t.term_id]]
					}
			})})

			response.data.postToTermNumber = postToTermNumber;
					
			dispatch({type: termActions.fetchTermsSuccess, terms: response.data})
		} catch (e) {
			dispatch({type: termActions.fetchTermsError, error: e.message})
		}
	}

}





