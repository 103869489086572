import React from 'react';
import Post from "./Post";
import { CSSTransition, TransitionGroup, } from 'react-transition-group';
import Col from 'react-bootstrap/Col';

const PostList = ({posts, title, remove, description}) => {
	if (posts.length > 0) {
	return (
		<Col xs={12} md={11} lg={10} className="content m-auto">
			<div className="content__h1 mb-4 mt-3 p-1 text-left m-0">
				<div className="m-0">
					<span className="h1">{title}</span>
				</div>
			</div>
			<div className="content__description p-1 mb-4 text-muted">
				<div className="m-0">
					{description}
				</div>
			</div>
			<TransitionGroup className="todo-list content__main content-list">
						{posts.map((postItem, i) => { 
						return (
							<CSSTransition
								timeout={500}
								classNames="item"
								key={postItem.ID}
							>
								<div 
									key={postItem.ID}
									className={'content-list__item ' + (postItem.big ? 'content-list__item_big' : '') + 
									(postItem.visible ? '' : ' d-none')}>
									<Post post={{...postItem, number: i + 1, }} remove={remove} /> 
								</div>
							</CSSTransition>
						)}
						)}
			</TransitionGroup>
			<div className="content__workplace row">
			</div>
		</Col>

	) 
	} else
	return (
		<div>
			<h2 style={{textAlign:'center'}}>Пока здесь пусто</h2>
		</div>
	)
}
export default PostList;
