import './css/style.scss';
import React, {useEffect} from 'react';
import {BrowserRouter} from "react-router-dom";
import Navbar from "./components/UI/navbar/Navbar";
import Footer from "./components/UI/footer/Footer";
import AppRouter from "./components/AppRouter";
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "./components/UI/loader/Loader";
import {PostsBookProvider} from './hooks/usePostsBook';
import {useActions } from './hooks/useActions'
import {useSelector} from 'react-redux'


function App() {
	const {fetchTerms, authNonce} = useActions()
	const {terms, loading, error} = useSelector(state => state.term)
	
	useEffect ( () => {
		fetchTerms()
		authNonce()
	}, [])

	return (
		<PostsBookProvider>
		<BrowserRouter>
			<Container fluid className="p-0 m-0 main">
					<Navbar 
						categories={terms.categories}
					/>
				{error && 
					<h2 style={{color:'red',textAlign:'center'}}>Error occured: ${error}</h2>
				}
				{loading ?
						<Loader />
					:
						<>
						<AppRouter/>
						<Footer 
							categories={terms.categories}
						/>
						</>
				}
			</Container>
		</BrowserRouter>
		</PostsBookProvider>
	)
}



export default App;
