import React, {useContext, useEffect} from 'react';
import cl from "./Navbar.module.scss";
import {TermsContext} from '../../../context/index.js';
import {usePostsBook} from "../../../hooks/usePostsBook"
import {useNavigate} from "react-router-dom";
import Logo from "./Logo";
import {useSelector} from 'react-redux'

const Navbar = ({categories}) => {
	const {categoryId, setCategoryId} = usePostsBook()
	const {terms} = useSelector(state => state.term)
	const navigate = useNavigate()
	//console.log('prarams', params)

	//*** PATH
	const PATH = {
		closeToggler: '.'+cl['toggler-close'],
		expandableNav: '.'+cl['main-nav__expandable'],
		navItem: '.'+cl['main-nav__item'],
		openToggler: '.'+cl['toggler-open'],
	};


	useEffect ( () => {
			// *** HELPERS
			const editDataAttr = (elem, dataAttr, newVal) => {
				elem.dataset[dataAttr] = newVal;
			};

			const setDataAtrsToNavElems = () => {
				const navElems = Array.from(document.querySelectorAll(PATH.navItem));

				navElems.forEach((elem, index) => {
					editDataAttr(elem, "addText", `0${index}`);
				});
			};
			setDataAtrsToNavElems()
			document.body.addEventListener("click", (e) => {
				const target = e.target;
				//console.log(PATH.openToggler)
				if (target.closest(PATH.openToggler)) {
					const nav = document.querySelector(PATH.expandableNav);
					nav.classList.add(cl['js-open']);
				}
				if (target.closest(PATH.closeToggler)) {
					const nav = document.querySelector(PATH.expandableNav);
					nav.classList.remove(cl['js-open']);
				}
			})
		},
		[]
	)

	if (typeof categories != 'undefined') {
		const cats = Object.values(categories)
		return (
			<nav className={cl.navbar + ' ' + cl['main-nav']}>
				<div className={cl.navbar__logo}>
					<a href="/"
						key={0}
						onClick={(e) => {
							e.preventDefault()
							setCategoryId(0)
							navigate('/') 
						}}
						className={cl['logo-link']}
					>
						<Logo />
					</a>
				</div>
				<div className={cl.navbar__links}>
					{
						cats.map ( (c,i) => 
							(<a href={`/${terms.terms[terms.termsBook[Number(c.id)]]['slug']}/`}
								data-ref={`/${terms.terms[terms.termsBook[Number(c.id)]]['slug']}/`}
								key={i}
								onClick={(e) => {
									e.preventDefault()
									setCategoryId(Number(c.id))
									navigate(`/${terms.terms[terms.termsBook[Number(c.id)]]['slug']}`)
								}}
								className={categoryId == c.id ? cl.active : cl.passive}
								>	
								{c.name}
							</a>)
						)
					}
				</div>
				<div className={cl.navbar__toggler}>
				</div>
				<button className={cl['main-nav__toggler'] + ' ' + cl['toggler-open']} type="button" title="Открыть ворота">
					<div className={cl['toggler-open__bar']}></div>
					<div className={cl['toggler-open__bar']}></div>
					<div className={cl['toggler-open__bar']}></div>
				</button>

				<div className={cl['main-nav__expandable']}>
					<div className={cl['main-nav__expandable-inner']}>
					<div className={cl['container']}>
						<div className={cl['main-nav__expandable-content']}>
							<button className={cl['main-nav__toggler'] + ' ' +
													cl['toggler-close']}
							 type="button" title="Close Menu"></button>
							<ul className={cl['main-nav__list']}>
								{
								cats.map ( (c,i) => 
									(
										<li className={cl['main-nav__item']} key={i} data-add-text="data-add-text"
											title={c.name}
										>
										<a href={`/${terms.terms[terms.termsBook[Number(c.id)]]['slug']}/`}
											data-ref={`/${terms.terms[terms.termsBook[Number(c.id)]]['slug']}/`}
											key={i}
											onClick={(e) => {
												e.preventDefault()
												document.querySelector(PATH.expandableNav).classList.remove(cl['js-open']);
												setCategoryId(Number(c.id))
												navigate(`/${terms.terms[terms.termsBook[Number(c.id)]]['slug']}`)
											}}
											className={cl['main-nav__link']}
											>	
											<span className={cl['main-nav__link-title']}>
												{c.name}
											</span>
											<span className={cl['main-nav__link-descr']}>
												{c.description}
											</span>
										</a>
										</li>)
								)
								}
							</ul>
						</div>
					</div>
					</div>
				</div>
			</nav>
		)
	} else {
		return (
			<div className={cl.navbar}>
				<div className={cl.navbar__links}>

				</div>
			</div>
		)
	}
}

export default Navbar;
