import Posts from '../pages/Posts';
import PostIdPage from '../pages/PostIdPage';
import Error from "../pages/Error";
import Search from "../pages/Search";
import Blog from "../pages/Blog";
import Login from "../pages/Login";
import Declinaton from "../pages/Declination";

export const privateRoutes = [
	{path: '/login', element: Login},
]


export const publicRoutes = [
	{path: '/error', element: Error},
	{path: '/dmlyrae', element: () => <Blog nicename="dm" />},
	{path: '/search', element: () => <Search />},
	{path: '/search/:word', element: () => <Search />},
	{path: '/', element: () => <Posts />},
	{path: '/author/:name/', element: Blog},
	{path: '/:category/:id', element: PostIdPage},
	{path: '/tools/declination', element: Declinaton},
]
