import React, {useMemo} from 'react';

export const getPagesCount = (totalCount, limit) => {
	return Math.ceil(totalCount / limit)
}

export const validateForm = (request) => {
	let result = {
		email: typeof request.email === 'undefined',
		content: typeof request.content === 'undefined',
		name: typeof request.name === 'undefined',
		address: typeof request.address === 'undefined',
	}
	return result;
}

export const determingImportance = (n, list) => {
			if (!list[n]['has_thumbnail']) return false
			if (list.length < 5) return true
			let candidate = [{
				number: n,
				rating: list[n]['rating'],
				post_length: list[n]['post_length'],
			}]
			//console.log('candidate', candidate)
			//console.log('list['+ n +']', list[n])
			let startNumber = -2
			let finishNumber = 2
			if (n < 2) {startNumber = -n; finishNumber += (2-n) }
			if (n > (list.length - 4)) {startNumber -= 3 - (list.length - n); finishNumber = list.length- n - 1}
			//console.log('n, s, f', n, startNumber, finishNumber)
			if (n > 1) {
				if (list[n-2]['big']) { startNumber++;finishNumber++}
				if (finishNumber === list.length) return false;
				if (list[n-1]['big']) {startNumber++;finishNumber++}
				if (finishNumber === list.length) return false;
			}
			for (let i = n + startNumber; i < (n + finishNumber+1); i++) {
				if (list[i]['has_thumbnail']) candidate.push({
					rating: list[i]['rating'],
					post_length: list[i]['post_length'],
					number: i,
				})
			}
			//console.log('candidate', candidate)
			const winner = candidate.reduce( (pr, cur) => {
				if (pr.rating > cur.rating) return pr; 
				if (pr.rating < cur.rating) return cur; 
				if (pr.post_length > cur.post_length) return pr;
				return cur;
			});
			//console.log('winner', winner);
			return winner.number === n;
		}
