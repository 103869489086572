export const defaultState = {
	isAuth: false,
	loadingAuth: false,
	JWT: '',
	wpNonce: '',
}

export const authActions = {
	authTry: 'AUTH_TRY',
	authNonce: 'AUTH_NONCE',
	authSuccess: 'AUTH_SUCCESS',
	authError: 'AUTH_ERROR',
}
