import React, {useState, useContext, useEffect, useRef, useMemo} from 'react';
import '../css/style.scss';
import PostList from "../components/PostList";
import PostFilter from "../components/PostFilter";
import Pagination from "../components/UI/pagination/Pagination.jsx";
import Loadmore from "../components/UI/loadmore/Loadmore.jsx";
import {usePosts} from "../hooks/usePosts";
//import {usePagesArray} from "../hooks/usePagination";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import Loader from "../components/UI/loader/Loader";
import {getPagesCount, determingImportance} from "../utils/pages"; 
import {useParams} from "react-router-dom";
//import Error from "../pages/Error"; 
import {usePostsBook} from "../hooks/usePostsBook"
import {useSelector} from "react-redux"

function Posts({categoryStart}) {
	//const {terms}=useContext(TermsContext);
	//console.log('terms posts', terms)
	const params = useParams();
	const {terms} = useSelector(state => state.term)
	//console.log('params in Posts', params)

	//console.log(terms)


	const {appendPostsBook, categoryId, setCategoryId} = usePostsBook()
	const category = useMemo (() => {
		if (categoryStart) return {...terms.categories[categoryStart],
				posts: terms.terms[terms.termsBook[terms.categories[categoryStart]['id']]]['posts']}
		if (params.category && terms.categories) return {...terms.categories[params.category], 
				posts: terms.terms[terms.termsBook[terms.categories[params.category]['id']]]['posts']}
		return {id: 0, posts: [], name: 'Новые посты', description: ''}
		
	}, [categoryId])
	//console.log('start category', category)
	const [posts, setPosts] = useState([ ])
	//console.log(posts)
	const [filter, setFilter] = useState({sort: 'sortById', query: ''})
	const [totalPages, setTotalPages] = useState (0)
	const [limit, setLimit] = useState(10)
	const [page, setPage] = useState(1)
	const sortedAndSearchedPosts = usePosts(posts, filter.sort, filter.query)
	const [referencePostsBook, setReferencePostsBook] = useState({})
	const loadmore = useRef()
	const observer = useRef()
	const [postsEnd, setPostsEnd] = useState(false)


	
	const [fetchPosts, isPageLoading, postError] = useFetching( async (limit, page, catId, newPage) => {
		const response = await (catId ? 
								PostService.getPostsFromCategory(limit,page,catId) : 
								PostService.getAllPosts(limit, page, 0));

		const refBook = {}
		setPostsEnd(response.data.posts.length < (limit))
		//console.log('post length', response.data.posts.length)
		response.data.posts.forEach((p,i) => {
			refBook[p['ID']] = i
			p.visible = true
			p.big = determingImportance(i, response.data.posts)
			p.terms = terms.postToTermNumber[p.ID].map (tn => {return {
				name: terms.terms[tn]['name'],
				terms_id: terms.terms[tn]['term_id'],
				number: tn,
				slug: terms.terms[tn]['slug'],
				tax: terms.terms[tn]['taxonomy'],
				desc: terms.terms[tn]['description'],
			}})
		})
		setReferencePostsBook({...referencePostsBook, ...refBook})

		if (newPage) {
			setPosts([...response.data.posts])
			//console.log('newPage loaded ' + category.name)
		} else {
			setPosts([...posts, ...response.data.posts])
		}

		let totalCount = category.posts.length;

		if (!totalCount) totalCount = response.data.total_count;
		//console.log('total', totalCount)
		setTotalPages(getPagesCount(totalCount, limit))
	})
	const removePost = (post) => {
		setPosts(posts.filter( p => p.id !== post.id))
	}
	const changePage = (p) => {
		if (p < 1) return
		if (p === page) {
			setPosts(posts.map((post) => {
				return {...post, visible: true}
			}))
		} else {
			setPosts(posts.map((post,i) => {
				if (i < p * limit || i >= (p + 1) * limit ) {
					return {...post, visible: false}
				} else {
					return {...post, visible: true}
				}
			}))
			let listElem = document.querySelector('.content-list')
			window.scrollTo({
				top: listElem.getBoundingClientRect().top + window.pageYOffset,
				behavior: "smooth"
			});
		}
		setPage(p)
	}


	useEffect ( () => {
		//console.log('isPageLoading top', isPageLoading)
		if (isPageLoading) return;
		if (postError) return;
		if (observer.current) {
			//console.log('disconnect')
			observer.current.disconnect()
		} 
		//console.log('observer current', observer.current)
		var callback = async function(entries, observer) {
			if (entries[0].isIntersecting) {
				//console.log('observer postsEnd', postsEnd)
				if (!postsEnd) {
					//console.log('isPageLoading', isPageLoading)
					//console.log('div in viewport')
					if (posts.length > 0) {
						if (category.posts.includes(posts[0]['ID']) || category.id === 0) {
							setPage(page + 1)
							fetchPosts(limit, page-1, category.id, false)
							appendPostsBook(posts)
							return;
						}
					}
					setPage(1)
					//console.log('before fetch', category)
					fetchPosts(limit, 0, category.id, true)
					appendPostsBook(posts)
				} else {
					//console.log('posts end')
				}
			}
		}
		observer.current = new IntersectionObserver(callback);
		observer.current.observe(loadmore.current);
		//console.log('observer current', observer.current)
	}, [isPageLoading])

	useEffect ( () => {
		setPostsEnd(false)
		if (isPageLoading) return;
		if (postError) return;
		if (posts.length > 0) {
			if (category.posts.includes(posts[0]['ID'])) {
				return;
			}
		}
		//setPage(1)
		fetchPosts(limit, 0, category.id, true)
		appendPostsBook(posts)
	}, [categoryId])

	useEffect ( () => {
		setPostsEnd(false)
		setCategoryId(Number(category.id))
		fetchPosts(limit, 0, category.id, false)
		appendPostsBook(posts)
	}, [])


	return (
		<div className="App">
			<PostFilter 
				filter={filter}
				setFilter={setFilter}
			/>
				{postError && 
					<h2 style={{color:'red',textAlign:'center'}}>Error occured: ${postError}</h2>
				}
				<PostList 
					remove={removePost} 
					posts={sortedAndSearchedPosts} 
					title={category.name ? category.name : 'Новые статьи'}
					description={category.description ? category.description : ''}
				/> 
				{isPageLoading && <Loader /> }
				<div ref={loadmore}>
					<Loadmore 
						visible={!postsEnd}
					/>
				</div>
			<Pagination 
				page={page} 
				changePage={changePage} 
				totalPages={totalPages} 
			/>
		</div>	
	)
}

export default Posts;
