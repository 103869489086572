import React, {useState, useMemo} from 'react';
import { CSSTransition, TransitionGroup, } from 'react-transition-group';
import Col from 'react-bootstrap/Col';
import {useNavigate} from "react-router-dom";
import { WiMeteor } from 'react-icons/wi';
import {GiStarSattelites} from 'react-icons/gi'
import Pagination from "../components/UI/pagination/Pagination.jsx";

const PostListFlat = ({posts, description, title, needName, special, icons}) => {
	const navigate = useNavigate()
	const [page, setPage] = useState(1)
	const size='3rem'
	const firstCard = <WiMeteor		size={size}		style={{color: '#fd9283'}}	/>;
	const limit = 10
	const cStyles = useMemo (() => posts.map( (p, i) => (i > (page - 1) * limit && i < page * limit ? 'block' : 'none')) , [page])
	if (!icons) {
		icons = [
			firstCard,
		]
	}
	const changePage = (p) => {
		setPage(p)
		let listElem = document.querySelector('.content-list')
		window.scrollTo({
				top: listElem.getBoundingClientRect().top + window.pageYOffset,
				behavior: "smooth"
		});
	}
	if (posts.length > 0) {
		return (
		<Col xs={12} md={11} lg={10} className="content m-auto">
			<div className="content__description p-1 mb-4 text-muted">
				<div className="m-0">
					{title}
				</div>
				<div className="m-0">
					{description}
				</div>
			</div>
			<TransitionGroup className="todo-list content__main content-list mb-3">
						{posts.map((postItem, i) => { 
						return (
							<CSSTransition
								timeout={500}
								classNames="item"
								key={postItem.ID}
							>
								<div key={postItem.ID} className="card rounded" style={{display: cStyles[i] || ( i < 10 ? '' : 'none')}} >
										<a href = {postItem.post_name} 
											className="card-link img-link" 
											onClick={(e) => {
											e.preventDefault(); 
											let categoryUrl = '';
											postItem.terms.forEach( t => {
												if (t.tax !== 'post_tag'){categoryUrl= t.slug;//console.log('slug', t)
												}
											})
											navigate(`/${categoryUrl}/${postItem.post_name}`);}}
										>
											{special ?
												<div className="p-3 p-lg-5 text-center text-light bg-dark rounded-top" style={{cursor:'pointer'}}>
													{i === 0 ? 
														firstCard
														:
														icons[postItem.ID % icons.length]
													}
												</div>
											:	
											(postItem.has_thumbnail ?
											<img src={postItem.thumbnail_url} className="card-img-top" alt={postItem.post_title}
											/>
										:
											<div className="p-3 p-lg-5 text-center text-light bg-dark">
													<GiStarSattelites  
														size={'4rem'}
													/>
											</div>)
										}
									</a>
									  <div className="card-body bg-image">
										<h5 className="card-title">{postItem.post_title}</h5>
										<h6 className="card-subtitle mb-2 text-muted">{postItem.post_date}</h6>
										<p className="card-text">
											{postItem.post_excerpt ?
											<>{postItem.post_excerpt}</>
											:
											<></>
											}
										</p>
										<a href = {postItem.post_name} 
											className="card-link" 
											onClick={(e) => {
											e.preventDefault(); 
											let categoryUrl = '';
											postItem.terms.forEach( t => {
												if (t.tax !== 'post_tag'){categoryUrl= t.slug;//console.log('slug', t)
												}
											})
											navigate(`/${categoryUrl}/${postItem.post_name}`);}}
										>
											<span className={"badge " + (special ? "bg-success" : "bg-primary")}>открыть</span>
											</a>
										{ needName ? 
												<a href={`/author/${postItem.author.user_nicename}`} className="card-link">
													{postItem.author.user_nicename}
												</a>
											:
											<>
											</>
										}
									  </div>
								
									<div className="post-flat">
										<div className="post-flat__date date">
										</div>
									</div>
                                </div>
							</CSSTransition>
						)}
						)}
			</TransitionGroup>
			<Pagination 
				page={page} 
				changePage={changePage} 
				totalPages={ (posts.length % limit === 0 ? posts.length/limit : Math.floor(posts.length/limit) + 1)} 
			/>
			<div className="content__workplace row">
			</div>
		</Col>
	)} else
	return (
		<div>
			<h2 style={{textAlign:'center'}}>Ничего не найдено...</h2>
		</div>
	)
}
export default PostListFlat;
