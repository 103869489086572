import {defaultState, authActions} from '../vars/auth'

export const authReducer = (state = defaultState, action ) => {
	switch (action.type)  {
		case authActions.authTry:
			return {...state, loadingAuth: true}
		case authActions.authNonce:
			return {...state, wpNonce: action.nonce}
		case authActions.authSuccess:
			return {...state, loadingAuth: false, isAuth: action.isAuth, JWT: action.JWT}
		case authActions.authError:
			return {...state, loadingAuth: false, isAuth: false}
		default:
			return state
	}
}
