import {Routes, Route, Navigate} from "react-router-dom";
import React from 'react';
import {publicRoutes, privateRoutes} from '../router/';
import { useSelector } from "react-redux";
import Page from  "../components/page/Page"
import Posts from '../pages/Posts';

const AppRouter = () => {
	const {isAuth}=useSelector(state => state.auth);
	const terms = useSelector(state => state.term)
	return (
		isAuth ? 
			<Routes>
				{privateRoutes.map((r,i) => <Route element={<r.element />} path={r.path} key={r.path} />)}
				<Route path="/*" element={<Navigate to="/error" replace />} />
			</Routes>
		:
			<Routes>
				{terms.terms.pages.map ( (p,i) => <Route element={<Page address={p[0]}/>} path={'/' + p[0]} key={'p' + i} />)}
				{Object.keys(terms.terms.categories).map ((p,i) => <Route element={<Posts categoryStart={p} />}
				path={'/'+p} key={'cat' + i} />)}
					{publicRoutes.map((r,i) => <Route element={<r.element />} path={r.path} key={r.path} />)}
				<Route path="/*" element={<Navigate to="/error" replace />} />
			</Routes>
	)
}

export default AppRouter;
