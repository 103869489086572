import React, { useState, useEffect, useMemo } from 'react'
import cl from './Rating.module.scss'
import {BsStarFill, BsStar, BsStarHalf} from 'react-icons/bs'
import {useSelector} from 'react-redux'

const Rating = ({rating, postId}) => {
	const [userRate, setUserRate] = useState(() => {
		let r = localStorage.getItem('r:'+postId);
		if (!r) {
			localStorage.setItem('r:'+postId, 0)
			return 0
		}
		return Number(r)

	})
	const {terms} = useSelector(state => state.term)
	const ratingMap = useMemo (() => terms.terms.map( t => (t.name.length > 9 ? 10 : t.name.length )), [terms])
	const evaluateRate = (rMap, cr, ur) => { 
		return (rMap.reduce ( (e, pe) => {return e + pe}, Number(cr) + ur) / (rMap.length + 1 + (ur ? 1 : 0))).toFixed(2)
	}
	const [rate, setRate] = useState(evaluateRate(ratingMap, rating, userRate))

	const stars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
	const bgClasses = ['danger', 'danger', 'danger', 'danger', 'warning', 'warning', 'warning', 'primary', 'primary', 'success', 'success', 'ssuccess' ]
	const [commonStarsElementsClasses, setCommonStarsElementsClasses] = useState(stars.map (i => ['d-none', 'd-none', 'd-none']))
	const [userStarsElementsClasses, setUserStarsElementsClasses] = useState(stars.map (i => ['d-none', '']))
	const setRating = (r, sac) => { // sac = star classes array , r = rating
		let m = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
		return m.map( (c,i) => { // c = classNames array [0, 1, 2]
			if (i < r - 1) {
				return['', 'd-none', 'd-none']
			} else {
				if (i > r) {
					return['d-none', 'd-none', '']
				} else {
					if (r - i < 0.25) {
						return['d-none', 'd-none', '']
					} else {
						if (r - i >= 0.25 && r - i < 0.75) {
							return['d-none', '', 'd-none']
						} else {
							return['', 'd-none', 'd-none']
						}
					}
				}
			}
		})
	}
	const setUserRating = (r, sac) => {
		let m = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
		return m.map( (c,i) => { // c = classNames array [0, 1]
			if (i <= r - 1) {
				return['', 'd-none']
			} else {
				return['d-none', '']
			}
		})
	}
	// side-эффекты: Обновление классов звезд
	useEffect (() => {
		setCommonStarsElementsClasses(setRating(rate, commonStarsElementsClasses))
	}, [rate])
	useEffect (() => {
		setUserStarsElementsClasses(setUserRating(userRate, commonStarsElementsClasses))
		setRate(evaluateRate(ratingMap, rating, userRate))
		localStorage.setItem('r:'+postId, userRate)
	}, [userRate])

	return (
		<div className={cl['rating-container']}>
			<div className={cl['rating-block']}>
				<div className={cl['rating-block__title']}>
					<span className="fw-bold">Рейтинг: </span>
					<span className={"badge text-wrap fs-5 bg-" + bgClasses[Math.floor(rate)]}>{rate}</span>
				</div>
				<div className={cl['rating-block__stars']}>
					{ commonStarsElementsClasses.map ((c,i) => 
						<span key = {500 + i}>
							<BsStarFill 
								key={100 + i} 
								id={'s' + (100 + i)} 
								size='1.5rem'
								className={c[0]}
								onMouseEnter={e => {
									console.log('i', i)
								}}
							/>
							<BsStarHalf 
								key={110 + i} 
								id={'s' + (110 + i)} 
								size='1.5rem'
								className={c[1]}
								onMouseEnter={e => {
									console.log('i', i)
								}}
							/>
							<BsStar 
								key={120 + i} 
								id={'s' + (120 + i)} 
								size='1.5rem'
								className={c[2]}
								onMouseEnter={e => {
									console.log('i', i)
								}}
							/>
						</span>
					)}
				</div>
			</div>
			<div className={cl['rating-block']}>
				<div className={cl['rating-block__title']}>
					{userRate?
						<>
							<span className="fw-bold">Ваша оценка: </span>
							<span className={"badge text-wrap fs-5 bg-" + bgClasses[Math.floor(userRate)]} >{userRate}</span>
						</>
						:
						<>Ждём вашей оценки</>
					}
				</div>
				<div className={cl['rating-block__stars']}>
					{userStarsElementsClasses.map ((c, i) => 
						<span key = {1000 + i}>
						<BsStarFill 
							key={i} 
							id={'s' + (10 + i)} 
							size='1.5rem'
							className={c[0]}
							style={{cursor: 'pointer'}}
							onMouseEnter={e => {
								console.log('i', i)
							}}
							onClick={e => {
								setUserRate(i + 1)
							}}
						/>
						<BsStar 
							key={10 + i} 
							id={'s' + (10 + i)} 
							size='1.5rem'
							className={c[1]}
							style={{cursor: 'pointer'}}
							onMouseEnter={e => {
								console.log('i', i)
							}}
							onClick={e => {
								setUserRate(i + 1)
							}}
						/>
						</span>
					)}
				</div>
			</div>
		</div>
	)
}

export default Rating
