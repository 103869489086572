import React from 'react';
import MyInput from './UI/input/MyInput';
//import { FaBeer } from 'react-icons/fa';
import {useNavigate} from "react-router-dom";


const PostFilter = ({filter, setFilter}) => {
	const navigate=useNavigate()
	/*<MySelect 
			value={filter.sort} 
			onChangeCB={selSor => setFilter({...filter, sort: selSor})} 
			selectDef={true}
			defaultValue="Sort by..."
			options={[
				{name: 'Sort by name', value: 'sortByName'}, 
				{name: 'Sort by body', value: 'sortByBody'}, 
			]} 
			name="Sort post"
		/>*/
	const searchString = !filter.query ? '' : 
		(filter.query[0] === '_' ? filter.query.replace('_', '#') :
			filter.query).split('_').join(' ').trim()
	return (
		<div className="toolbar" >
			<div className="toolbar_buttons">
			</div>
			<div className="search m-auto col-lg-10 col-md-11 col-12">
				<MyInput 
					value={searchString}
					onChange={e => setFilter({...filter, query: e.target.value})} 
					placeholder="Поиск..." 
					onKeyPress={e => {
						let s = e.target.value
						if (e.charCode === 13) {
							navigate('/search/' + s.trim().replaceAll(' ', '_').replaceAll('#', '_'))
						}
					}}
				/>

			</div>
		</div>
	)
}
export default PostFilter;
