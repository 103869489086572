import React, {useState} from 'react';
import classes from './MyInput.module.css';

const MyInput = ({children, ...props}) => {
	return (
		<input {...props} className={classes.myInp}/>
	)
}

export default MyInput;
