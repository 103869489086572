import React  from 'react';
import cl from './Loadmore.module.scss';

const Loadmore = ({visible}) => {
	return (
		<div  className={cl['load-more'] + ' m-auto col-lg-10 col-md-11 col-12'} style={{display: visible ? 'block' : 'none' }}>
			<button>
				Загрузить ещё
			</button>
		</div>
	);
}

export default Loadmore;
