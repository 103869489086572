import React, {useState, useEffect} from 'react';
import PostService from "../../API/PostService";
import {useFetching} from "../../hooks/useFetching";
import Loader from "../../components/UI/loader/Loader";
import {useSelector} from "react-redux"
import cl from './Page.module.scss'
import parse from 'html-react-parser';

const Page = ({address}) => {
	const {terms} = useSelector(state => state.term)
	const [post, setPost] = useState({})

	useEffect(() => {
		//console.log('postsBook', postsBook)
		if (isLoadingId) return
		if (!terms.hasOwnProperty('terms')) return
		fetchPostById(undefined, encodeURI(address).toLowerCase())
	}, [])


	const [fetchPostById, isLoadingId, error] = useFetching( async (id, idString = '') => {
		const response = await PostService.getPostById(id, idString)
		if (!response.data.error) {
			const id = Number(response.data.result.ID)
			let seo ={}
			if (document.title[0] === 'G' && document.title[1] === 'o') {
				if (typeof response.data.seo === 'string') {
					seo.title = response.data.result.post_title
					seo.description = response.data.result.post_excerpt
					seo.keywords = []
				} else {
					seo.title = response.data.seo.title || response.data.result.post_title
					if (seo.title[0] === '#') seo.title = response.data.post_title
					seo.description =  response.data.seo.description || response.data.result.post_excerpt
					if (seo.description[0] === '#') seo.description = response.data.post_excerpt
					seo.keywords =  response.data.seo.keywords || '[]'
					seo.keywords = JSON.parse(seo.keywords)
					seo.keyphrases =  response.data.seo.keyphrases
					seo.canonical_url = response.data.seo.canonical_url
				}
			}
			response.data.result.seo = seo;
			response.data.result.rating = response.data.rating;
			setPost(response.data.result)
		}
	})

	//console.log('isLoadingId', isLoadingId)

	return (
		<div className='page-content'>
			{error && 
				<h2 style={{color:'red',textAlign:'center'}}>Error occured: ${error}</h2>
			}
			{isLoadingId || !post.post_title ?
				<Loader /> :
				<div className={cl['page-container']}>
					<div className={cl['page']}>
						<div className={cl['page__title'] + ' ' + cl['title']}>
							<div className={cl['title__info']}>
								<span className="h1">{post.post_title}</span>
							</div>
						</div>
						<div className={cl['page__body'] + ' ' + cl['body']}>
							{parse(post.post_content)}
						</div>
					</div>
				</div>
			}
		</div>
	)
}


export default Page
