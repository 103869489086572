import React from 'react';
import cl from "./Logo.module.css";

const Logo = ({categories}) => {
	return (
		<div className={cl.logo}>
		<svg viewBox="0 0 118.19 118.19" className={cl['logo-image']}><defs id="SvgjsDefs3411"></defs>

			<g id="logo-image-inner" className={cl['logo-image-inner']} featurekey="4K7G0D-0" fill="#ff325d">
				<g xmlns="http://www.w3.org/2000/svg">

<path d="M62.549,48.452l-0.898,0.659c1.846,2.5,2.822,5.469,2.822,8.584h0.557h0.557H76.45l-11.323-3.76   C64.644,51.968,63.774,50.112,62.549,48.452z"></path>

<path d="M37.451,66.943l0.898-0.659c-1.846-2.505-2.822-5.474-2.822-8.589h-0.557h-0.557H23.55l11.323,3.765   C35.356,63.423,36.226,65.278,37.451,66.943z"></path>

<path d="M60.625,47.07l0.396-0.396l7.681-7.681l-10.664,5.347c-1.733-1.05-3.657-1.748-5.703-2.056l-0.166,1.104   c3.071,0.459,5.859,1.87,8.066,4.072L60.625,47.07z"></path>

<path d="M39.375,68.325l-0.396,0.391l-7.681,7.681l10.664-5.342c1.733,1.045,3.657,1.743,5.703,2.051l0.166-1.099   c-3.071-0.464-5.859-1.875-8.066-4.077L39.375,68.325z"></path>

<path d="M35.688,55.527c0.156-1.011,0.42-1.992,0.771-2.93l-0.718-1.196c-0.552,1.25-0.942,2.578-1.152,3.96L35.688,55.527z"></path>

<path d="M50,43.223v-0.557v-0.557V31.25l-3.76,11.318c-0.869,0.215-1.719,0.508-2.534,0.869l1.196,0.718   C46.514,43.55,48.232,43.223,50,43.223z"></path>

<path d="M64.312,59.863c-0.156,1.016-0.42,1.992-0.771,2.935l0.718,1.191c0.552-1.25,0.942-2.578,1.152-3.96L64.312,59.863z"></path>

<path d="M50,72.168v0.557v0.557v10.864l3.76-11.323c0.869-0.215,1.719-0.508,2.534-0.869l-1.196-0.718   C53.486,71.846,51.768,72.168,50,72.168z"></path>

<path fill="#fff" d="M52.368,56.245c0.654,1.074,0.527,2.49-0.4,3.418s-2.344,1.06-3.418,0.4l-3.291,3.291l26.172,15.771L55.659,52.954   L52.368,56.245z"></path>

<path d="M47.632,59.146c-0.654-1.069-0.527-2.49,0.4-3.418s2.344-1.055,3.418-0.4l3.291-3.291L28.569,36.265l15.771,26.172   L47.632,59.146z"></path>
	</g></g>

			<g id="logo-image-outer" className={cl['logo-image-outer']} featurekey="4K7G0D-0" fill="#ff325d">
				<g xmlns="http://www.w3.org/2000/svg">

<path d="M54.258,15.605c-0.156-2.217-2.002-3.965-4.258-3.965s-4.102,1.748-4.258,3.965c-21.333,2.144-38.047,20.2-38.047,42.09   C7.695,81.021,26.675,100,50,100s42.305-18.979,42.305-42.305C92.305,35.806,75.591,17.749,54.258,15.605z M75.762,82.285   c-0.327-0.313-0.84-0.313-1.162,0.01s-0.322,0.84-0.01,1.167c-3.047,2.905-6.602,5.278-10.522,6.968   c-0.176-0.425-0.659-0.63-1.084-0.459s-0.63,0.654-0.464,1.079c-3.647,1.372-7.583,2.153-11.685,2.251   c-0.015-0.454-0.381-0.815-0.835-0.815s-0.82,0.361-0.835,0.815c-4.36-0.103-8.525-0.986-12.363-2.515   c0.176-0.425-0.024-0.908-0.444-1.089c-0.42-0.176-0.903,0.015-1.089,0.435c-3.657-1.665-6.987-3.931-9.858-6.67   c0.313-0.327,0.313-0.845-0.01-1.167s-0.835-0.322-1.162-0.01c-2.91-3.047-5.283-6.602-6.973-10.522   c0.425-0.171,0.63-0.659,0.459-1.084s-0.654-0.63-1.079-0.464c-1.372-3.647-2.153-7.583-2.246-11.685   c0.449-0.015,0.811-0.381,0.811-0.835s-0.361-0.82-0.811-0.83c0.098-4.365,0.981-8.53,2.51-12.368   c0.425,0.176,0.908-0.02,1.089-0.444c0.176-0.42-0.015-0.903-0.43-1.089c1.665-3.657,3.926-6.987,6.67-9.858   c0.327,0.313,0.84,0.313,1.162-0.01s0.322-0.835,0.01-1.162c3.047-2.905,6.602-5.283,10.522-6.973   c0.176,0.425,0.659,0.63,1.084,0.459s0.63-0.654,0.464-1.079c3.403-1.279,7.051-2.051,10.854-2.227L50,25.571l1.665-3.457   c4.058,0.186,7.939,1.06,11.533,2.495c-0.176,0.42,0.024,0.903,0.444,1.084s0.903-0.015,1.089-0.43   c3.662,1.665,6.987,3.926,9.858,6.67c-0.313,0.327-0.313,0.84,0.01,1.162s0.84,0.322,1.162,0.015   c2.91,3.042,5.283,6.597,6.973,10.518c-0.425,0.176-0.63,0.659-0.459,1.084s0.654,0.635,1.079,0.464   c1.372,3.652,2.153,7.583,2.246,11.689c-0.449,0.01-0.811,0.376-0.811,0.83s0.361,0.82,0.811,0.835   c-0.098,4.36-0.981,8.525-2.51,12.363c-0.425-0.176-0.908,0.024-1.089,0.444c-0.176,0.42,0.015,0.908,0.43,1.089   C80.767,76.089,78.506,79.414,75.762,82.285z"></path>

<path d="M44.561,14.39c0.156-0.547,0.396-1.064,0.708-1.528c-1.255-0.391-2.354-1.045-2.612-2.148   c-0.439-1.885,0.156-4.028,1.626-5.879C45.83,2.881,48.022,1.67,50,1.67s4.17,1.211,5.718,3.164   c1.47,1.851,2.065,3.994,1.626,5.879c-0.259,1.104-1.357,1.758-2.612,2.148c0.308,0.464,0.552,0.981,0.708,1.528   c1.66-0.562,3.12-1.548,3.53-3.301c0.552-2.383-0.156-5.039-1.943-7.295C55.166,1.455,52.476,0,50,0s-5.166,1.455-7.026,3.794   c-1.787,2.256-2.495,4.912-1.943,7.295C41.44,12.842,42.9,13.828,44.561,14.39z"></path>

</g></g>

	</svg>
	<div className={cl['logo-text']}> </div>
	</div>
	);
}

export default Logo;
