import axios from 'axios';
import {useSelector} from 'react-redux'

export default class PostService {
	static url = ''//'http://www.wptest.site'
	static async authTry(token) {
		if (token) {
			return {isAuth: false, JWT: '', error: false}
		}
		return {isAuth: false, JWT: '', error: false}
	}
	static checkNonce (nonce) {
		if (nonce) return nonce
		const e = document.getElementById('_wpnonce')
		if (e) return e.value
		return 'x'
	}
	static async getByAuthor(author, limit, page, nonce, auth) {
			const response = await axios({
				method: 'POST',
				url: this.url +'/wp-admin/admin-ajax.php?action=get_by_author',
				data: {author, limit, page, nonce: this.checkNonce(nonce), auth},
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			})
			//console.log('get_by_author', response)
			return (response);
	}
	static async getAllPosts(limit, page, nonce, auth) {
			const response = await axios({
				method: 'POST',
				url: this.url +'/wp-admin/admin-ajax.php?action=get_posts',
				data: {limit, page, cid: 0, nonce: this.checkNonce(nonce), auth},
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			})
			//console.log('getAllPosts', response)
			return (response);
	}
	static async getPostsFromCategory(limit, page, cid, nonce, auth) {
			//console.log('cid', cid)
			const response = await axios({
				method: 'POST',
				url: this.url +'/wp-admin/admin-ajax.php?action=get_posts',
				data: {limit, page, cid, nonce:  this.checkNonce(nonce), auth},
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			})
			//console.log(response.data)
			return (response);
	}
	static async getPostsFromArray(limit,ids, nonce, auth) {
			const response = await axios({
				method: 'POST',
				url: this.url+ '/wp-admin/admin-ajax.php?action=get_from_array',
				data: {limit, ids, nonce:  this.checkNonce(nonce), auth},
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			})
			//console.log(response.data)
			return (response);
	}
	static async getAllTerms(nonce, auth) {
			const response = await axios({
				method: 'POST',
				url: this.url + '/wp-admin/admin-ajax.php?action=get_terms',
				data: { nonce, auth},
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			})
			return (response);
	}
	static async getPostById(id, idString,  nonce, auth) {
		const response = await axios({
		method: 'POST',
			url: this.url + '/wp-admin/admin-ajax.php?action=get_by_id',
			data: {id, idString, nonce :this.checkNonce(nonce), auth},
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
		})
		//console.log('by id ', response.data)
		return (response);
	}
	static async getCommentById(id, idString, nonce, auth) {
		const response = await axios({
			method: 'POST',
			url: this.url + '/wp-admin/admin-ajax.php?action=get_com_by_id',
			data: {id, idString, nonce: this.checkNonce(nonce), auth},
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
		})
		return (response);
	}
	static async getPostsFromSearch (limit, search, nonce, auth) {
		const response = await axios({
			method: 'POST',
			url: this.url + '/wp-admin/admin-ajax.php?action=get_from_search',
			data: {search, nonce: this.checkNonce(nonce), auth},
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
		})
		return (response);
	}

}
