import React, {useState, useEffect} from 'react';
import '../css/style.scss';
import PostListFlat from "../components/PostListFlat";
import PostFilter from "../components/PostFilter";
import {usePosts} from "../hooks/usePosts";
//import {usePagesArray} from "../hooks/usePagination";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import Loader from "../components/UI/loader/Loader";
import {useParams} from "react-router-dom";
import {useSelector} from 'react-redux'
//import Error from "../pages/Error"; 

function Search() {
	const {terms}=useSelector( state => state.term)
	const params = useParams();
	//console.log('params in Posts', params)
	//console.log('start category', category)
	const [posts, setPosts] = useState([ ])
	//console.log(posts)
	const [filter, setFilter] = useState({sort: 'sortById', query: (params.word || '')})
	const sortedAndSearchedPosts = usePosts(posts, filter.sort, filter.query)
	useEffect ( () => {
		if (!terms.hasOwnProperty('terms')) return
		if (!params.word) return
		let searchString = params.word.replaceAll('_', ' ').trim().replaceAll('  ', ' ').replaceAll('  ', ' '); 
		if (params.word[0] === '_') {
			const tag = terms.terms.filter( t => t.name === searchString)
			if (tag.length > 0) {
				fetchPosts(20, tag[0]['posts'], filter.query)	
			}
		} else {
			fetchPosts(20, searchString, filter.query)
		}

	}, [])
	
	const [fetchPosts, isPageLoading, postError] = useFetching( async (limit, search, query) => {
		const response = await (typeof search === 'string' ? PostService.getPostsFromSearch(limit, search) : PostService.getPostsFromArray(limit, search))
		//response.data.result.forEach(i => {console.log(i)})
		response.data.result.forEach(p => {
			if (terms.postToTermNumber[p.ID]) {
				p.terms = terms.postToTermNumber[p.ID].map (tn => {
					return {
						name: terms.terms[tn]['name'],
						terms_id: terms.terms[tn]['term_id'],
						number: tn,
						slug: terms.terms[tn]['slug'],
						tax: terms.terms[tn]['taxonomy'],
						desc: terms.terms[tn]['description'],
				}})
			} else {
				p.terms = []
			}
		})
		setPosts([...response.data.result.map (p => {return {...p, post_content : query}})])
	})
	return (
		<div className="App">
			    <PostFilter 
				    filter={filter}
				    setFilter={setFilter}
			    />
				{postError && 
					<h2 style={{color:'red',textAlign:'center'}}>Error occured: ${postError}</h2>
				}
				<PostListFlat 
					posts={sortedAndSearchedPosts} 
					title={'Вот что мы нашли'}
					description={''}
					needName={true}
				/> 
				{isPageLoading && <Loader /> }
		</div>	
	)
}

export default Search;
