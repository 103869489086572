import React from 'react';

const Error = () => {
	return (
		<div className="col-12 col-md-11 col-lg-10 content m-auto">
			<div className="p-1 mb-4">
					<div className="m-0 p-1">
							<h1 style={{color: 'red'}}>Страница не найдена :(
							</h1>
					</div>
			</div>
		</div>
	)
}

export default Error;
