import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import PostService from "../API/PostService";
import {useFetching} from "../hooks/useFetching";
import Loader from "../components/UI/loader/Loader";
import Content from "../components/UI/content/Content";
import {usePostsBook} from "../hooks/usePostsBook"
import {useSelector} from "react-redux"
import ComList from '../components/comlist/ComList'
import Rating from '../components/UI/rating/Rating'

const PostIdPage = ({address}) => {
	const {terms} = useSelector(state => state.term)
	const params = useParams()
	const {postsBook} = usePostsBook()
	const [post, setPost] = useState({})
	const [comments, setComments] =useState([])

	useEffect(() => {
		//console.log('postsBook', postsBook)
		if (isLoadingId) return
		if (!terms.hasOwnProperty('terms')) return
		if (postsBook[encodeURI(params.id).toLowerCase()] && !address) {
			fetchPostById(postsBook[encodeURI(params.id).toLowerCase()]);
			fetchComments(postsBook[encodeURI(params.id).toLowerCase()]);
		} else {
			const ad = address || params.id
			fetchPostById(postsBook[encodeURI(ad).toLowerCase()], encodeURI(ad).toLowerCase())
			fetchComments(postsBook[encodeURI(ad).toLowerCase()], encodeURI(ad).toLowerCase(), Boolean(address))
		}
	}, [])
	const [errorPHP, setErrorPHP] = useState(false)


	const [fetchPostById, isLoadingId, error] = useFetching( async (id, idString = '') => {
		const response = await PostService.getPostById(id, idString)
		if (!response.data.error) {
			const id = Number(response.data.result.ID)
			if (terms.postToTermNumber[id]) {
				response.data.result.terms = terms.postToTermNumber[id].map( t => terms.terms[t])
			} else {
				response.data.result.terms = []
			}
			response.data.result.category = response.data.result.terms.filter( t => t.taxonomy === 'category')[0];
			let seo ={}
			if (document.title[0] === 'G' && document.title[1] === 'o') {
				if (typeof response.data.seo === 'string') {
					seo.title = response.data.result.post_title
					seo.description = response.data.result.post_excerpt
					seo.keywords = []
				} else {
					seo.title = response.data.seo.title || response.data.result.post_title
					if (seo.title[0] === '#') seo.title = response.data.post_title
					seo.description =  response.data.seo.description || response.data.result.post_excerpt
					if (seo.description[0] === '#') seo.description = response.data.post_excerpt
					seo.keywords =  response.data.seo.keywords || '[]'
					seo.keywords = JSON.parse(seo.keywords)
					seo.keyphrases =  response.data.seo.keyphrases
					seo.canonical_url = response.data.seo.canonical_url
				}
			}
			response.data.result.seo = seo;
			response.data.result.rating = response.data.rating;
			setPost(response.data.result)
		} else {
			setErrorPHP(response.data.errorMessage)
		}
	})

	//console.log('isLoadingId', isLoadingId)

	const [fetchComments, isComLoading, comError] = useFetching (async (id, idString = '', work) => {
		if (isComLoading) return
		if (work) {
			setComments([])
		} else {
			const response = await PostService.getCommentById(id, idString)
			if (!response.data.error) setComments(response.data.result)
		}
	})
	return (
		<div className='page-content'>
			{error && 
				<h2 style={{color:'red',textAlign:'center'}}>Случилась ошибка: ${error}</h2>
			}
			{errorPHP ? 
				<h2 style={{color:'red',textAlign:'center'}}>{errorPHP}</h2>
				:
				<>
				{isLoadingId || !post.post_title ?
					<Loader /> :
					<>
					<Content
						isLoading={isLoadingId} 
						post={post}
					/>
					<Rating rating={post.rating.meta_value} postId={post.ID} />
					</>
				}
					
				{comError && 
					<h2 style={{color:'red',textAlign:'center'}}>Случилась ошибка: ${error}</h2>
				}
				{isComLoading || !comments.comments || address ? 
					<Loader /> :
					<ComList 
						isComLoading={isComLoading}
						comments={comments}

					/>
				}
				</>
			}
		</div>
	)
}

export default PostIdPage;
