import React, {useState, useMemo, useEffect,} from 'react';
import {Helmet} from "react-helmet-async";

function Declination() {
	let placemark = {}
	let myMap = {}
	const script = document.createElement('script');
	script.src = "https://api-maps.yandex.ru/2.1/?apikey=f5224c7d-4f04-40aa-b796-f8c0b85cd467&lang=ru_RU";
	script.defer = true;
	script.type = 'text/javascript';
	script.async = true;
	const [longitude, setLongitude] = useState(37.573856)
	const [latitude, setLatitude] = useState(55.751574)
	const [altitude, setAltitude] = useState(0)
	let now = new Date()
	const [date, setDate] = useState(now.getFullYear() + "-" + (now.getMonth() > 8 ? '' : '0') + (now.getMonth() + 1) + "-" + (now.getDate() > 8 ? '' : '0') + now.getDate())
	const [sign, result] = useMemo ( () => {
		const cof = `    2020.0            WMM-2020        12/10/2019
  1  0  -29404.5       0.0        6.7        0.0
  1  1   -1450.7    4652.9        7.7      -25.1
  2  0   -2500.0       0.0      -11.5        0.0
  2  1    2982.0   -2991.6       -7.1      -30.2
  2  2    1676.8    -734.8       -2.2      -23.9
  3  0    1363.9       0.0        2.8        0.0
  3  1   -2381.0     -82.2       -6.2        5.7
  3  2    1236.2     241.8        3.4       -1.0
  3  3     525.7    -542.9      -12.2        1.1
  4  0     903.1       0.0       -1.1        0.0
  4  1     809.4     282.0       -1.6        0.2
  4  2      86.2    -158.4       -6.0        6.9
  4  3    -309.4     199.8        5.4        3.7
  4  4      47.9    -350.1       -5.5       -5.6
  5  0    -234.4       0.0       -0.3        0.0
  5  1     363.1      47.7        0.6        0.1
  5  2     187.8     208.4       -0.7        2.5
  5  3    -140.7    -121.3        0.1       -0.9
  5  4    -151.2      32.2        1.2        3.0
  5  5      13.7      99.1        1.0        0.5
  6  0      65.9       0.0       -0.6        0.0
  6  1      65.6     -19.1       -0.4        0.1
  6  2      73.0      25.0        0.5       -1.8
  6  3    -121.5      52.7        1.4       -1.4
  6  4     -36.2     -64.4       -1.4        0.9
  6  5      13.5       9.0       -0.0        0.1
  6  6     -64.7      68.1        0.8        1.0
  7  0      80.6       0.0       -0.1        0.0
  7  1     -76.8     -51.4       -0.3        0.5
  7  2      -8.3     -16.8       -0.1        0.6
  7  3      56.5       2.3        0.7       -0.7
  7  4      15.8      23.5        0.2       -0.2
  7  5       6.4      -2.2       -0.5       -1.2
  7  6      -7.2     -27.2       -0.8        0.2
  7  7       9.8      -1.9        1.0        0.3
  8  0      23.6       0.0       -0.1        0.0
  8  1       9.8       8.4        0.1       -0.3
  8  2     -17.5     -15.3       -0.1        0.7
  8  3      -0.4      12.8        0.5       -0.2
  8  4     -21.1     -11.8       -0.1        0.5
  8  5      15.3      14.9        0.4       -0.3
  8  6      13.7       3.6        0.5       -0.5
  8  7     -16.5      -6.9        0.0        0.4
  8  8      -0.3       2.8        0.4        0.1
  9  0       5.0       0.0       -0.1        0.0
  9  1       8.2     -23.3       -0.2       -0.3
  9  2       2.9      11.1       -0.0        0.2
  9  3      -1.4       9.8        0.4       -0.4
  9  4      -1.1      -5.1       -0.3        0.4
  9  5     -13.3      -6.2       -0.0        0.1
  9  6       1.1       7.8        0.3       -0.0
  9  7       8.9       0.4       -0.0       -0.2
  9  8      -9.3      -1.5       -0.0        0.5
  9  9     -11.9       9.7       -0.4        0.2
 10  0      -1.9       0.0        0.0        0.0
 10  1      -6.2       3.4       -0.0       -0.0
 10  2      -0.1      -0.2       -0.0        0.1
 10  3       1.7       3.5        0.2       -0.3
 10  4      -0.9       4.8       -0.1        0.1
 10  5       0.6      -8.6       -0.2       -0.2
 10  6      -0.9      -0.1       -0.0        0.1
 10  7       1.9      -4.2       -0.1       -0.0
 10  8       1.4      -3.4       -0.2       -0.1
 10  9      -2.4      -0.1       -0.1        0.2
 10 10      -3.9      -8.8       -0.0       -0.0
 11  0       3.0       0.0       -0.0        0.0
 11  1      -1.4      -0.0       -0.1       -0.0
 11  2      -2.5       2.6       -0.0        0.1
 11  3       2.4      -0.5        0.0        0.0
 11  4      -0.9      -0.4       -0.0        0.2
 11  5       0.3       0.6       -0.1       -0.0
 11  6      -0.7      -0.2        0.0        0.0
 11  7      -0.1      -1.7       -0.0        0.1
 11  8       1.4      -1.6       -0.1       -0.0
 11  9      -0.6      -3.0       -0.1       -0.1
 11 10       0.2      -2.0       -0.1        0.0
 11 11       3.1      -2.6       -0.1       -0.0
 12  0      -2.0       0.0        0.0        0.0
 12  1      -0.1      -1.2       -0.0       -0.0
 12  2       0.5       0.5       -0.0        0.0
 12  3       1.3       1.3        0.0       -0.1
 12  4      -1.2      -1.8       -0.0        0.1
 12  5       0.7       0.1       -0.0       -0.0
 12  6       0.3       0.7        0.0        0.0
 12  7       0.5      -0.1       -0.0       -0.0
 12  8      -0.2       0.6        0.0        0.1
 12  9      -0.5       0.2       -0.0       -0.0
 12 10       0.1      -0.9       -0.0       -0.0
 12 11      -1.1      -0.0       -0.0        0.0
 12 12      -0.3       0.5       -0.1       -0.1
999999999999999999999999999999999999999999999999
999999999999999999999999999999999999999999999999`;

					var newDecl = new declination(cof);
					var geoDec = newDecl.mag;

					function declination (model) {
						'use strict';
						var wmm,
							maxord = 12,
							a = 6378.137,
							b = 6356.7523142,   
							re = 6371.2,
							a2 = a * a,
							b2 = b * b,
							c2 = a2 - b2,
							a4 = a2 * a2,
							b4 = b2 * b2,
							c4 = a4 - b4,
							z = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
							unnormalizedWMM;

						function parseCof(cof) {
							wmm = (function (cof) {
								var modelLines = cof.split('\n'), wmm = [], i, vals, epoch, model, modelDate;
								for (i in modelLines) {
									if (modelLines.hasOwnProperty(i)) {
										vals = modelLines[i].replace(/^\s+|\s+$/g, "").split(/\s+/);
										if (vals.length === 3) {
											epoch = parseFloat(vals[0]);
											model = vals[1];
											modelDate = vals[2];
										} else if (vals.length === 6) {
											wmm.push({
												n: parseInt(vals[0], 10),
												m: parseInt(vals[1], 10),
												gnm: parseFloat(vals[2]),
												hnm: parseFloat(vals[3]),
												dgnm: parseFloat(vals[4]),
												dhnm: parseFloat(vals[5])
											});
										}
									}
								}

								return {epoch: epoch, model: model, modelDate: modelDate, wmm: wmm};
							}(cof));
						}

						function unnormalize(wmm) {
							var i, j, m, n, D2, flnmj,
								c = [z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice()],
								cd = [z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice()],
								k = [z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice()],
								snorm = [z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice(), z.slice()],
								model = wmm.wmm;
							for (i in model) {
								if (model.hasOwnProperty(i)) {
									if (model[i].m <= model[i].n) {
										c[model[i].m][model[i].n] = model[i].gnm;
										cd[model[i].m][model[i].n] = model[i].dgnm;
										if (model[i].m !== 0) {
											c[model[i].n][model[i].m - 1] = model[i].hnm;
											cd[model[i].n][model[i].m - 1] = model[i].dhnm;
										}
									}
								}
							}
							snorm[0][0] = 1;

							for (n = 1; n <= maxord; n++) {
								snorm[0][n] = snorm[0][n - 1] * (2 * n - 1) / n;
								j = 2;

								for (m = 0, D2 = (n - m + 1); D2 > 0; D2--, m++) {
									k[m][n] = (((n - 1) * (n - 1)) - (m * m)) /
										((2 * n - 1) * (2 * n - 3));
									if (m > 0) {
										flnmj = ((n - m + 1) * j) / (n + m);
										snorm[m][n] = snorm[m - 1][n] * Math.sqrt(flnmj);
										j = 1;
										c[n][m - 1] = snorm[m][n] * c[n][m - 1];
										cd[n][m - 1] = snorm[m][n] * cd[n][m - 1];
									}
									c[m][n] = snorm[m][n] * c[m][n];
									cd[m][n] = snorm[m][n] * cd[m][n];
								}
							}
							k[1][1] = 0.0;

							unnormalizedWMM = {epoch: wmm.epoch, k: k, c: c, cd: cd};
						}

						this.setCof = function (cof) {
							parseCof(cof);
							unnormalize(wmm);
						};
						this.getWmm = function () {
							return wmm;
						};
						this.setUnnorm = function (val) {
							unnormalizedWMM = val;
						};
						this.getUnnorm = function () {
							return unnormalizedWMM;
						};
						this.getEpoch = function () {
							return unnormalizedWMM.epoch;
						};
						this.setEllipsoid = function (e) {
							a = e.a;
							b = e.b;
							re = 6371.2;
							a2 = a * a;
							b2 = b * b;
							c2 = a2 - b2;
							a4 = a2 * a2;
							b4 = b2 * b2;
							c4 = a4 - b4;
						};
						this.getEllipsoid = function () {
							return {a: a, b: b};
						};
						this.calculate = function (glat, glon, h, date) {
							if (unnormalizedWMM === undefined) {
								throw new Error("ÐÐµÑ‚ ÐºÐ¾ÑÑ„Ñ„Ð¸Ñ†Ð¸ÐµÐ½Ñ‚Ð¾Ð² WMM.")
							}
							if (glat === undefined || glon === undefined) {
								throw new Error("ÐÐµ Ð·Ð°Ð´Ð°Ð½Ñ‹ ÑˆÐ¸Ñ€Ð¾Ñ‚Ð° Ð¸Ð»Ð¸ Ð´Ð¾Ð»Ð³Ð¾Ñ‚Ð°.");
							}
							function rad2deg(rad) {
								return rad * (180 / Math.PI);
							}
							function deg2rad(deg) {
								return deg * (Math.PI / 180);
							}
							function decimalDate(date) {
//                              date = new Date (date);
								var year = date.getFullYear(),
									daysInYear = 365 +
										(((year % 400 === 0) || (year % 4 === 0 && (year % 100 > 0))) ? 1 : 0),
									msInYear = daysInYear * 24 * 60 * 60 * 1000;

								return date.getFullYear() + (date.valueOf() - (new Date(year, 0)).valueOf()) / msInYear;
							}

							var epoch = unnormalizedWMM.epoch,
								k = unnormalizedWMM.k,
								c = unnormalizedWMM.c,
								cd = unnormalizedWMM.cd,
								alt = h || 0,
								dt = decimalDate(date) - epoch,
								rlat = deg2rad(glat),
								rlon = deg2rad(glon),
								srlon = Math.sin(rlon),
								srlat = Math.sin(rlat),
								crlon = Math.cos(rlon),
								crlat = Math.cos(rlat),
								srlat2 = srlat * srlat,
								crlat2 = crlat * crlat,
								q,
								q1,
								q2,
								ct,
								st,
								r2,
								r,
								d,
								ca,
								sa,
								aor,
								ar,
								br = 0.0,
								bt = 0.0,
								bp = 0.0,
								bpp = 0.0,
								par,
								temp1,
								temp2,
								parp,
								D4,
								m,
								n,
								fn = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
								fm = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
								z = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
								tc = [z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice()],
								sp = z.slice(),
								cp = z.slice(),
								pp = z.slice(),
								p = [z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice()],
								dp = [z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice(), z.slice(), z.slice(), z.slice(), z.slice(), z.slice(),
									z.slice()],
								bx,
								by,
								bz,
								bh,
								ti,
								dec,
								dip,
								gv;
							sp[0] = 0.0;
							sp[1] = srlon;
							cp[1] = crlon;
							tc[0][0] = 0;
							cp[0] = 1.0;
							pp[0] = 1.0;
							p[0][0] = 1;
							q = Math.sqrt(a2 - c2 * srlat2);
							q1 = alt * q;
							q2 = ((q1 + a2) / (q1 + b2)) * ((q1 + a2) / (q1 + b2));
							ct = srlat / Math.sqrt(q2 * crlat2 + srlat2);
							st = Math.sqrt(1.0 - (ct * ct));
							r2 = (alt * alt) + 2.0 * q1 + (a4 - c4 * srlat2) / (q * q);
							r = Math.sqrt(r2);
							d = Math.sqrt(a2 * crlat2 + b2 * srlat2);
							ca = (alt + d) / r;
							sa = c2 * crlat * srlat / (r * d);

							for (m = 2; m <= maxord; m++) {
								sp[m] = sp[1] * cp[m - 1] + cp[1] * sp[m - 1];
								cp[m] = cp[1] * cp[m - 1] - sp[1] * sp[m - 1];
							}

							aor = re / r;
							ar = aor * aor;

							for (n = 1; n <= maxord; n++) {
								ar = ar * aor;
								for (m = 0, D4 = (n + m + 1); D4 > 0; D4--, m++) {

									if (n === m) {
										p[m][n] = st * p[m - 1][n - 1];
										dp[m][n] = st * dp[m - 1][n - 1] + ct *
											p[m - 1][n - 1];
									} else if (n === 1 && m === 0) {
										p[m][n] = ct * p[m][n - 1];
										dp[m][n] = ct * dp[m][n - 1] - st * p[m][n - 1];
									} else if (n > 1 && n !== m) {
										if (m > n - 2) { p[m][n - 2] = 0; }
										if (m > n - 2) { dp[m][n - 2] = 0.0; }
										p[m][n] = ct * p[m][n - 1] - k[m][n] * p[m][n - 2];
										dp[m][n] = ct * dp[m][n - 1] - st * p[m][n - 1] -
											k[m][n] * dp[m][n - 2];
									}

									tc[m][n] = c[m][n] + dt * cd[m][n];
									if (m !== 0) {
										tc[n][m - 1] = c[n][m - 1] + dt * cd[n][m - 1];
									}

									par = ar * p[m][n];
									if (m === 0) {
										temp1 = tc[m][n] * cp[m];
										temp2 = tc[m][n] * sp[m];
									} else {
										temp1 = tc[m][n] * cp[m] + tc[n][m - 1] * sp[m];
										temp2 = tc[m][n] * sp[m] - tc[n][m - 1] * cp[m];
									}
									bt = bt - ar * temp1 * dp[m][n];
									bp += (fm[m] * temp2 * par);
									br += (fn[n] * temp1 * par);

									if (st === 0.0 && m === 1) {
										if (n === 1) {
											pp[n] = pp[n - 1];
										} else {
											pp[n] = ct * pp[n - 1] - k[m][n] * pp[n - 2];
										}
										parp = ar * pp[n];
										bpp += (fm[m] * temp2 * parp);
									}
								}
							}

							bp = (st === 0.0 ? bpp : bp / st);

							bx = -bt * ca - br * sa;
							by = bp;
							bz = bt * sa - br * ca;

							bh = Math.sqrt((bx * bx) + (by * by));
							ti = Math.sqrt((bh * bh) + (bz * bz));
							dec = rad2deg(Math.atan2(by, bx));
							dip = rad2deg(Math.atan2(bz, bh));

							if (Math.abs(glat) >= 55.0) {
								if (glat > 0.0 && glon >= 0.0) {
									gv = dec - glon;
								} else if (glat > 0.0 && glon < 0.0) {
									gv = dec + Math.abs(glon);
								} else if (glat < 0.0 && glon >= 0.0) {
									gv = dec + glon;
								} else if (glat < 0.0 && glon < 0.0) {
									gv = dec - Math.abs(glon);
								}
								if (gv > 180.0) {
									gv -= 360.0;
								} else if (gv < -180.0) { gv += 360.0; }
							}

							return {dec: dec, dip: dip, ti: ti, bh: bh, bx: bx, by: by, bz: bz, lat: glat, lon: glon, gv: gv};
						};
						this.calc = this.calculate;
						this.mag = this.calculate;

						if (model !== undefined) {
							if (typeof model === 'string') { 
								parseCof(model);
								unnormalize(wmm);
							} else if (typeof model === 'object') { 
								this.setUnnorm(model);
							} else {
								throw new Error("Invalid argument type");
							}
						}
					}
			let lat = parseFloat(latitude),
				lon = -parseFloat(longitude),
				alt = parseFloat(altitude),
				date_calc = new Date(date);
			if (isNaN(lat) || isNaN(lon) || isNaN(alt) || isNaN(date_calc)) {
				return [+1, 360]
			} else {
				lat = (Math.sign(-lat) * (Math.floor(Math.abs(lat) / 180) % 2) * 180) + (lat % 180);
				lon = (Math.sign(lon) * (Math.floor(Math.abs(lon) / 180) % 2) * 180) + (-lon % 180);
				let azimuth = geoDec(lat, lon, alt, date_calc).dec
				let znak = {2: 'E', 0: 'W'} [(azimuth / Math.abs (azimuth)) +1]
				return [znak, Math.abs (azimuth)]
			}
	}, [longitude, latitude, altitude, date])

	// КОМПАС
	useEffect( () => {
						let compass = document.querySelector('#compass')
						let ctx = compass.getContext("2d")
						let r = (sign === 'E' ? result : -result)
						ctx.setTransform(1, 0, 0, 1, 0, 0)
						ctx.clearRect(0, 0, compass.width, compass.height)
						ctx.translate(compass.width/2, compass.height/2)
						ctx.beginPath();
						ctx.strokeStyle="#000";
						ctx.arc(0, 0, 65, 0, Math.PI * 2);
						ctx.fillStyle = '#edecd7';
						ctx.fill();	
						ctx.closePath();
						ctx.beginPath();
						ctx.moveTo(0, -80); ctx.lineTo(0, -55); 
						ctx.stroke();
						ctx.moveTo(0, 55); ctx.lineTo(0, 80);
						ctx.stroke();
						ctx.moveTo(55, 0); ctx.lineTo(80, 0);
						ctx.stroke();
						ctx.moveTo(-55, 0); ctx.lineTo(-80, 0);
						ctx.stroke();
						ctx.moveTo(-66, 0);
						ctx.arc(0, 0, 66,  0, Math.PI * 2);
						ctx.lineWidth=1;ctx.strokeStyle="#000";
						ctx.stroke();
						ctx.closePath();


	

						ctx.beginPath();
						for (var i = 0; i < Math.PI * 2; i += Math.PI/18) {
							ctx.moveTo(0, 0);
							ctx.arc(0, 0, 65, i, i);
							ctx.stroke();
						}
						ctx.closePath();



						ctx.beginPath();
						ctx.arc(0, 0, 57, 0, Math.PI * 2);
						ctx.fillStyle = '#fdfff1';
						ctx.fill();
						ctx.moveTo(57, 0);
						ctx.arc(0, 0, 57, 0, Math.PI * 2);
						ctx.strokeStyle="#808080";
						ctx.stroke();
						ctx.strokeStyle="#000";
						ctx.fillStyle = '#000';
						ctx.textBaseline = "middle";
						ctx.textAlign = "right";
						ctx.font = 'bold 20px sans-serif';
						ctx.strokeText("W", -82, 0);
						ctx.font = 'bold 10px sans-serif';
						ctx.fillText("90°",57, 0);
						ctx.textAlign = "left";
						ctx.fillText("270°", -57, 0);
						ctx.textAlign = "center";
						ctx.fillText("0°", 2, -48);
						ctx.fillText("180°", 0, 48);
						ctx.textAlign = "left";
						ctx.font = 'bold 20px sans-serif';
						ctx.strokeText("E", 82, 0);
						ctx.closePath();
						ctx.rotate(r * (Math.PI / 180));
						ctx.closePath();
						ctx.beginPath();
						if (sign === 'W') {
							ctx.arc(0, 0, 73, Math.PI * 1.5, Math.PI * 1.5 - (r * (Math.PI / 180)));
							ctx.lineWidth=10;
							ctx.strokeStyle="#008080";
							ctx.stroke();
							ctx.closePath();
							ctx.beginPath();	
							ctx.arc(0, 0, 68, Math.PI * 1.5 - (r* (Math.PI / 180)), Math.PI * 3.5);
							ctx.lineWidth=4;
							ctx.strokeStyle="#76a837";
							ctx.stroke();
							ctx.closePath();
						} else {
							ctx.arc(0, 0, 73, Math.PI * 1.5 - (r* (Math.PI / 180)), -Math.PI/2);
							ctx.lineWidth=10;
							ctx.strokeStyle="#008080";
							ctx.stroke();
							ctx.closePath();
							ctx.beginPath();
							ctx.arc(0, 0, 68, - Math.PI/2, -(r* (Math.PI / 180)) - Math.PI/2);
							ctx.lineWidth=4;
							ctx.strokeStyle="#76a837";
							ctx.stroke();
							ctx.closePath();
						}
						ctx.closePath();
						ctx.beginPath();
						var coor = [{'x': 12, 'y': 0}, {'x': 0, 'y': -65}, {'x': -12, 'y': 0}, {'x': 0, 'y': 65}, {'x': 12, 'y': 0}];
						ctx.moveTo(coor[0]['x'], coor[0]['y']);
						for (var i = 0; i<3; i++) {
							ctx.lineTo(coor[i]['x'], coor[i]['y']);
						}
						ctx.lineTo(coor[0]['x'], coor[0]['y']);
						ctx.fillStyle = '#ec1e24';ctx.lineWidth=1;ctx.strokeStyle="#000";
						ctx.fill();
						ctx.stroke();
						ctx.closePath();
						ctx.beginPath();
						for (var i = 2; i<5; i++) {
							ctx.lineTo(coor[i]['x'], coor[i]['y']);
						}
						ctx.fillStyle = '#fff';
						ctx.fill();
						ctx.stroke();
						ctx.arc(0, 0, 3, 0, Math.PI * 2);
						ctx.stroke();
						ctx.moveTo(0, -80); ctx.lineTo(0, -63); 
						ctx.lineWidth=1;
						ctx.strokeStyle="#000";
						ctx.stroke();
						ctx.closePath();	
	}, [result])
	const resultHtml = useMemo ( () => {
		if (result !== 360) {
			return '<p class="nomargin fs-5">Магнитное склонение ' + {'E':
				'восточное', 'W': 'западное'}[sign] + ':</p><p class="nomargin fs-5"><b>' + 
				result.toFixed(4) + '°</b> ' + sign + ' (' +
				Math.floor(result) + '°' + Math.floor((result - Math.floor(result))
						* 60) + '\''+ Math.floor((result*100 -
							Math.floor(result*100)) * 60) + '" '+ sign +')';
		} else {
			return '<p class="nomargin fs-5">Не хватает данных.</p>';
		}
	}, [result])
	useEffect( () => {
		if (document.querySelector('ymap')) return
		document.body.appendChild(script);
		script.onload = () => {
				if (document.querySelector('ymap')) return
				window.ymaps.ready ( () => {
							myMap = new window.ymaps.Map("mapid", {
								center: [56, 38],
								zoom: 6,
								controls: ['zoomControl'],
							}, {
								balloonMaxWidth: 200,
								searchControlProvider: 'yandex#search'
							})
							placemark = new window.ymaps.Placemark([55.751574, 37.573856], {}, { preset: 'islands#redIcon'
							});
							myMap.geoObjects.add(placemark);
							let now = new Date()
							document.querySelector('#date').value = now.getFullYear() + "-" + (now.getMonth() > 8 ? '' : '0') + (now.getMonth() + 1) + "-" + (now.getDate() > 8 ? '' : '0') + now.getDate();
							myMap.events.add('click', (e) => {
								e.preventDefault()
								let coor = e.get('coords')
								placemark.geometry.setCoordinates(coor)
								setLatitude(Number(coor[0]).toPrecision(4))
								setLongitude(Number(coor[1]).toPrecision(4))
							});
				})
			}


	}, [])
	return (
		<div className="declination">
				<Helmet>
					<title>Калькулятор магнитного склонения</title>
					<meta
					  name="description"
					  content={'Рассчитываем магниное склонение на карте.'}
					/>
				</Helmet>
			<div id = "mapid" className = "w-100 border bordered mb-3" style= {{
				minHeight: 200,
				minWidth: 320,
				cursor: 'crosshair',
			}}
			>

			</div>
						<div id="app_calculation">
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text">Широта:</span>
								</div>
								<input type="number" step="any" id="latitude" rows="15" className="form-control" 
									value={latitude}
									onChange={(e) => {setLatitude(Number(e.target.value))}}
								/>
							</div>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text">Долгота:</span>
								</div>
								<input type="number" step="any" id= "longitude"  rows="15" className="form-control"
									value={longitude}
									onChange={(e) => {setLongitude(Number(e.target.value))}}
							/>
							</div>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text">Дата:</span>
								</div>
								<input type="date" id= "date" rows="15" className="form-control"
									value={date}
									onChange={(e) => {setDate(e.target.value)}}
							/>
							</div>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text">Альтитуда:</span>
								</div>
								<input type="number" step="0.01" id= "altitude" rows="15"  className="form-control"
									value={altitude}
									onChange={(e) => {setAltitude(Number(e.target.value))}}
							/>
							</div>
							<button type="button" id="calculate" hidden={true} className="btn btn-outline-success">Рассчитать</button>
							<div className={'alert ' + (result === 360 ? 'alert-danger' : 'alert-success')} >
								<div id="result" className="text-center"  dangerouslySetInnerHTML={{__html: resultHtml}} >

								</div>
								<div className="text-center">
									<canvas id="compass" width="240" height="200"  v-draw-compass="resultTrue"></canvas>
								</div>
							</div>
						</div>
						<div id="declinaton__description" className="declinaton__description">
							<div className="row">
								<div className="m-auto p-1 mt-1 mb-2 col-12 col-md-10 col-lg-9 bg-white rounded p-5">
									<p className="p-2"> Склонение магнитной стрелки возникает из-за того, что магнитный полюс Земли не совпадает с географическим. Магнитосфера Земного шара возникает из взаимодействия нескольких переменных. Структуру магнитного поля определяют и космическая погода, и неравномерное движение магмы внутри планеты. Это сложный процесс, поэтому для навигации используется упрощеная модель: магнитный диполь планетарного масштаба.</p>
									<p className="p-2"> Метод расчёта скалярного (т.е. векторного) потенциала магнитного поля разработал немецкий математик Гаусс. Поэтому внутри любого калькулятора магнитного склонения используются ряды Гаусса, инструменты высшей математики (вроде полиномов Лежандра) и коэффициенты одной из моделей геомагнитного поля Земли, любезно предоставляемой Национальным центром геофизических данных США (NGDC). <a href = "https://en.wikipedia.org/wiki/World_Magnetic_Model#cite_note-6">Модель WMM</a> обновляется раз в несколько лет, в зависимости от скорости смещения магнитного полюса, актуальная версия - <a href ="https://www.ngdc.noaa.gov/geomag/WMM/soft.shtml">WMM2020</a>. Альтернативную, но практически идентичную модель предоставляет Международная ассоциация геомагнетизма и аэрономии. Это модель IGRF, использующая те же самые ряды Гаусса. Коэффициенты последней, 13-й модели <a href="https://www.ngdc.noaa.gov/IAGA/vmod/coeffs/igrf13coeffs.txt">здесь</a>. Формулу для самостоятельного расчёта можно найти в <a href = "https://ru.wikipedia.org/wiki/Ряд_Гаусса">Википедии</a>.</p>
									<p className="p-2">Проделать сложные математические расчёты "на коленке" затруднительно, поэтому вычислительные возможности современной техники здесь как нельзя кстати. Но почему нельзя просто рассчитать азимут магнитного склонения, т.е. определить направление на магнитный полюс как угол между географическим и магнитными полюсами? Если вы бросите металлическую стружку вокруг магнита, то увидите, что образумые <a href="https://media.springernature.com/lw685/springer-static/image/art%3A10.1186%2Fs40623-020-01288-x/MediaObjects/40623_2020_1288_Figa_HTML.png?as=webp">линии сложнее</a>, чем простые ортодромы. Поэтому магнитное склонение на карте отличается для точек с одинаковым азимутом относительно полюса. Строго говоря, стрелка компаса в математической модели вообще никогда не указывает на полюс, к которому притягивается. Однако на практике этот нюанс не имеет ни смысла, ни значения.</p>
									<p className="p-2">Калькуляторы, работающие на модели IGRF или WMM, дают большой запас точности на широтах менее 75° (в пределах сотых долей). Однако рядом с областью на поверхности планеты, которую мы называем магитным полюсом, погрешность будет значительной, ведь стрелка компаса здесь должна быть ориентирована в направлении центра Земли. Если вам всё же удастся опуститься с компасом ниже уровня литосферы и плыть по стрелке в слоях магмы, то вы опять будете вправе рассчитывать на онлайн-калькулятор магнитного склонения. :) И всё же подход Гаусса годится только для расчёта постоянных (бестоковых) магнитов, из-за чего отбрасывается переменное магнитное поле, возникающее с электрическими токами в верхних слоях атмосферы (радиационных поясах) и внутри планеты. Земной шар вращается вокруг оси и вокруг звезды, поэтому солнечный ветер оказывает непрерывно меняющееся (и в течение дня, и в течение года) воздействие на магнитосферу Земли. Интересующимся этим вопросом стоит почитать о модели <a href="https://ccmc.gsfc.nasa.gov/models/Tsyganenko%20Magnetic%20Field~TS05/">Цыганенко Н.А.</a></p>
								</div>
							</div>
						</div>
		</div>	
	)
}

export default Declination;
