import React from 'react';
import cl from './Loader.module.scss';

const Loader = () => {

	return (
		<div className={cl['loader-wrapper']}>
			<div className={cl.mainLoad}>

			</div>
		</div>
	)
}

export default Loader;
