import PostService from "../../API/PostService"
import {authActions} from '../vars/auth'


export const authTry = (token = '') => {
	return async (dispatch) => {
		try {
			dispatch({type: authActions.authTry})
			const response = await PostService.authTry(token);
			dispatch({type: authActions.authSuccess, isAuth: response.data.isAuth, JWT: response.data.JWT})
		} catch (e) {
			dispatch({type: authActions.authError, isAuth: false, JWT: ''})
		}
	}
}

export const authNonce = (token = '') => {
	return (dispatch) => {
		let wn = document.querySelector('#_wpnonce')
		if (wn) {
			dispatch({type: authActions.authNonce, nonce: wn.value})
		} else {
			dispatch({type: authActions.authNonce, nonce: ''})
		}
	}
}
