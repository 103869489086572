import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import parse from 'html-react-parser';
import { CSSTransition} from 'react-transition-group';


const Post = (props) => {
	const navigate = useNavigate();

	let tags = '';
	let category = '';
	let categoryUrl = '';
	props.post.terms.forEach( t => {
		if (t.tax === 'post_tag') {tags+=`<span class="tag">${t.name}</span>`} 
		else {category = t.name;categoryUrl= t.slug;//console.log('slug', t)
		}
	})
	useEffect( () => {
		//if (props.big) return;
		const postItem = document.querySelector('#post'+props.post['ID']);
		let h = postItem.offsetHeight - 60;
		let pc = Array.from(postItem.querySelector('.post-content').children)
		let pch = pc.map( (pcc,i) => {return {elem: pcc, h: pcc.offsetHeight}})
		let totalHeight = pch.reduce( (prev, pcc) => prev + pcc.h, 0)
		if (totalHeight > h) {
			pc[3].style.display='none';
			totalHeight -= pc[3]['h']
			if (totalHeight > h) {
				pc[4].style.display='none';
				totalHeight -= pc[4]['h']
				if (totalHeight > h) {
					pc[2].style.display='none';
				}
			} 
		}
	}, [])
	return (
			<CSSTransition
				timeout={500}
				classNames="post"
			>
			<div 
				className="post"
				id={'post'+props.post['ID']}
				href={`/posts/${props.post['ID']}`}
				onClick={(e) => {e.preventDefault(); navigate(`/${categoryUrl}/${props.post.post_name}`);}}
			>
				<div className={'background-layer ' + (props.post.big ? `` : ` bg_` + Math.floor(Math.random() * 10))} 
					style={{backgroundImage: props.post.big ? `url(${props.post.thumbnail_url})` : 'none',}} >
				</div>
				<div className="opacity-wrapper">
					<div className="post-content">
						<div className="post-content__head">
							<span className="head" href={`/posts/${props.post.ID}`} 
								onClick={(e)=> {e.preventDefault(); navigate(`/`)}}
								>
								{category}	
							</span>
						</div>
						<div className="post-content__h1">
							{props.post.post_title}
						</div>
						<div className="post-content__info">
							{props.post.post_date}
						</div>
						<div className="post-content__excerpt">
							{parse(props.post.post_content)}
						</div>
						<div className="post-content__tags">
							{parse(tags)}
						</div>
					</div>
				</div>
			</div>
			</CSSTransition>
	);
}
export default Post;
