import React from 'react'
import cl from './Footer.module.scss'
import {useSelector} from 'react-redux'
import SimpleLineIcon from 'react-simple-line-icons';



const Footer = ({categories}) => {
	const {terms} = useSelector(state => state.term)

	if (typeof categories === 'undefined') { categories = [] }
		const cats = Object.values(categories)
		return (
		<footer className={cl['site-footer']}>
		  <div className='container-fluid'>
			<div className='row'>
				<div className='m-auto col-lg-10 col-md-11 col-12'>
				  <div className='row'>
						  <div className={cl['quote'] + ' ' + cl['footer-block'] +' col-sm-12 col-md-6'}>
							<h6>Цитата дня</h6>
							<p className={cl['text-justify']}>{terms.quote}</p>
						  </div>

						  <div className={cl['footer-block'] + ' ' + cl['footer-block__left'] + ' col-6 col-md-3'}>
							<h6>Рубрики</h6>
							<ul className={cl['footer-links']}>
									{
										cats.map ( (c,i) => 
									(<li key={i}><a href={`/${terms.terms[terms.termsBook[Number(c.id)]]['slug']}/`}
												data-ref={`/${terms.terms[terms.termsBook[Number(c.id)]]['slug']}/`}
												key={i}
												>	
												{c.name}
									</a></li>)
										)
									}
							</ul>
						  </div>

						  <div className={cl['footer-block'] + ' col-6 col-md-3'}>
							  <h6>ссылки</h6>
							<ul className={cl['footer-links']}>
								{terms.pages.map ( (p,i) => 
									<li key={'fl'+i}><a href={'/' + p[0]}>{p[1]}</a></li>
								)}
							</ul>
						  </div>
					</div>
				</div>
			<hr />
			</div>
		  </div>
		  <div className='container-fluid'>
			<div className='row'>
				<div className='m-auto col-lg-10 col-md-11 col-12'>
				  <div className='row'>
							  <div className='col-md-8 col-sm-6 col-xs-12'>
								  <p className={cl['copyright-text']}>Copyright &copy; {(new Date()).getFullYear()} All Rights Reserved by 
								  {' '}<a href="http://go-hiking.ru">Go Hiking</a>.
								</p>
							  </div>

							  <div className='col-md-4 col-sm-6 col-xs-12 mt-4 mb-4 mt-md-0 mb-md-0'>
								<ul className={cl['social-icons']}>
									<li><a className={cl['vkontakte']} href={`${terms['terms_social_links']['vk']}`}>
											<SimpleLineIcon name="social-vkontakte" style={{
												fontSize:'26px', marginTop: '7px',
											}} />
									</a></li>
									<li><a className={cl['youtube']} href={`${terms['terms_social_links']['vk']}`}>
											<SimpleLineIcon name="social-youtube" style={{
												fontSize:'26px', marginTop: '7px',
											}} />
									</a></li>
									<li><a className={cl['instagram']} href={`${terms['terms_social_links']['vk']}`}>
											<SimpleLineIcon name="social-instagram" style={{
												fontSize:'26px', marginTop: '7px',
											}} />
									</a></li>
									<li><a className={cl['tumblr']} href={`${terms['terms_social_links']['vk']}`}>
											<SimpleLineIcon name="social-tumblr" style={{
												fontSize:'26px', marginTop: '7px',
											}} />
									</a></li>
									<li><a className={cl['twitter']} href={`${terms['terms_social_links']['vk']}`}>
											<SimpleLineIcon name="social-twitter" style={{
												fontSize:'26px', marginTop: '7px',
											}} />
									</a></li>
								</ul>
							  </div>
					</div>
				</div>
			</div>
		  </div>
	</footer>
	)
}

export default Footer;
