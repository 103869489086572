import React  from 'react';
import {usePagesArray} from "../../../hooks/usePagination.jsx";

const Pagination = ({totalPages, page, changePage}) => {
	const pagesArray = usePagesArray(totalPages);
	return (
		<div className="page__wrapper" style={{'display': (totalPages > 1 ? 'flex' : 'none')}}>
			<ul className="pagination pagination-lg" >
				<li className={"page-item " + (page === 1 ? 'disabled' : '')} >
					<a className="page-link" href="#" aria-label="назад"
						onClick={(e) => changePage(page-1)}
					>
						<span aria-hidden="true">&laquo;</span>
					</a>
				</li>
				{pagesArray.map((p,i) =>
				<li 
						className={page !== p ? 'page-item ' : 'page-item active '}
					key={'list' + i}
				>
					<a 
						className="page-link"
						key={p}
						onClick={(e) => changePage(p)}
				>{p}</a></li>)
				}
				<li className={"page-item " + (page >= totalPages ? 'disabled' : '')} >
					<a className="page-link" href="#" aria-label="вперед"
						onClick={(e) => changePage(page + 1)}
					>
						<span aria-hidden="true">&raquo;</span>
					</a>
				</li>
			</ul>
		</div>
	);
}

export default Pagination;
