import React from 'react'
import cl from "./ComList.module.scss";
import { BsArrowRightSquareFill } from 'react-icons/bs';
import {validateForm} from '../../utils/pages'


const ComList = ({comments, isComLoading}) => {
	const sendComment = (elem) => {
		let parent = elem.closest('#com_edit')
		let voc = {
			name : parent.querySelector('#com_name'),
			email : parent.querySelector('#com_email'),
			content : parent.querySelector('#com_content'),
		}
		let request = {}
		for (let key in voc) {request[key] = voc[key].innerHTML}
		let result = validateForm(request)
		for (let key in voc) {
			if (!result[key] && !voc[key].parentElement.classList.contains['alert-danger']) {
				voc[key].parentElement.classList.add('alert-danger')
			}
		}
	}
	return (
				<div className={cl['com-container']}>
					<div className={cl['com-container__title']} >
					</div>
					<div className={cl['com-container__body']} >
					{comments.comments.map(c => {
						const time = Math.floor((Date.now() - new Date(c.comment_date)) / 1000);
						const timeNames = [
							['секунд', 'секунд','минут'], 
							['минуту', 'минуты', 'минут'], 
							['час', 'часов', 'часов'], 
							['день', 'дня', 'дней'], 
							['месяц', 'месяца', 'месяцев'], 
							['год', 'года', 'лет'], 
							['десятилетие', 'десятилетиe', 'десятилетий' ], 
							['столетие', 'столетие', 'столетий'], 
							['тысячелетие', 'тысячелетий', ''], 
							['эпоху', 'эпох', '']]
						const timeBorders = [0.1, 60, 3600, 86400, 2548800, 31536000, 315360000, 3153600000, 315360000000, 3153600000000]
						let i=0
						while (timeBorders[i] < time) {
							i++
						}
						i--
						const count = Math.round(time/timeBorders[i])
						return (
						<div className={cl['comment']}>
							<div className={cl['comment__title']}><span className="fs-5 fw-bold d-block">{c.comment_author}</span></div>
							<div className={cl['comment__time']}>
								<span className="fw-thin d-block">
								написал(а) {count} {count > 1 ? (count < 5 ? timeNames[i][1] : timeNames[i][2]) : timeNames[i][0]} назад: 
								</span>
							</div>
							<div className={cl['comment__content']}>
								{c.comment_content}
							</div>
						</div>)
					})}
					<div className={cl['comment'] } id='com_edit'>
						<div className={cl['comment__title'] + ' alert m-0'}>
							<span id='com_name' className="fw-bold d-block" contentEditable="true"
								onFocus={(e) => {
										if (e.target.innerHTML.indexOf('Введите') > -1) {
											e.target.innerHTML = '';
										}
										if (e.target.parentElement.classList.contains('alert-danger')) {
											e.target.parentElement.classList.remove('alert-danger')
										}
								}}
								onBlur={(e) => {
									if (e.target.innerHTML === '') {
										e.target.innerHTML = 'Введите имя';
									}
								}}
								suppressContentEditableWarning={true}
								>
								Введите имя
							</span></div>
							<div className={cl['comment__email'] + ' alert m-0' }>
								<span id='com_email' className="fw-thin d-block m-0" contentEditable="true"
									onFocus={(e) => {
										if (e.target.innerHTML.indexOf('телефон') > -1) {
											e.target.innerHTML = '';
										}
										if (e.target.parentElement.classList.contains('alert-danger')) {
											e.target.parentElement.classList.remove('alert-danger')
										}
									}}
									onBlur={(e) => {
										if (e.target.innerHTML === '') {
											e.target.innerHTML = 'email или телефон';
										}
									}}
									suppressContentEditableWarning={true}
								>
									email или телефон
								</span>
							</div>
							<div className={cl['comment__content'] + ' alert m-0'} >
								<span id='com_content' className="fw-thin d-block m-0" contentEditable="true"
								suppressContentEditableWarning={true} 
									onFocus={(e) => {
										if (e.target.innerHTML.indexOf('комментарий') > -1) {
											e.target.innerHTML = '';
										}
										if (e.target.parentElement.classList.contains('alert-danger')) {
											e.target.parentElement.classList.remove('alert-danger')
										}
									}}
									onBlur={(e) => {
										if (e.target.innerHTML === '') {
											e.target.innerHTML = 'напишите что-нибудь';
										}
									}}
							>
								комментарий
								</span>
							</div>
							<div id='com_submit' className={cl['comment__submit']}
								onClick = {e => {
									sendComment(e.target)
								}}
							>
								<BsArrowRightSquareFill size="2rem"/>
							</div>
					</div>
					</div>
				</div>
	)
}

export default ComList
