import React, {useState, useEffect} from 'react';
import '../css/style.scss';
import PostListFlat from "../components/PostListFlat";
import PostFilter from "../components/PostFilter";
import {usePosts} from "../hooks/usePosts";
//import {usePagesArray} from "../hooks/usePagination";
import {useFetching} from "../hooks/useFetching";
import PostService from "../API/PostService";
import Loader from "../components/UI/loader/Loader";
import {useParams} from "react-router-dom";
import {useSelector} from 'react-redux'
import parse from 'html-react-parser';
//import Error from "../pages/Error"; 
import {Helmet} from "react-helmet-async";

import {GiAbstract019,
	GiAbstract093,
	GiAerodynamicHarpoon,
	GiAfrica,
	GiAirBalloon,
	GiAk47,
	GiAmmonite,
	GiAngelWings,
	GiAngularSpider,
	GiArmadillo,
	GiAtSea,
	GiAstronautHelmet,
	GiBackPain,
	GiBalloonDog,
	GiBanjo,
	GiBaseballGlove,
	GiBat,
	GiBeamsAura,
	GiBeanstalk,
	GiBearFace,
	GiBigWave,
	GiBison,
	GiBlast,
	GiBlackball,
	GiBirdTwitter,
	GiBrainTentacle,
	GiBurningForest,
	GiCamel,
	GiCargoShip,
	GiCentipede,
	GiChicken,
	GiCleopatra,
	GiCrossedClaws,
	GiCuauhtli,
	GiDiplodocus,
	GiDoubleFish,
	GiEagleEmblem,
	GiExplodingPlanet,
	GiFrozenOrb,
	GiKiwiBird,
	GiLockedChest,
	GiMoonOrbit,
	GiMushroomGills,
	GiNautilusShell,
	GiWinterGloves,
	GiUnlitBomb,
	GiThrowingBall,
	GiArcingBolt,
} from 'react-icons/gi'


const Blog = ({nicename}) =>  {
	const {terms}=useSelector( state => state.term)
	const params = useParams();
	const [posts, setPosts] = useState([ ])
	const [filter, setFilter] = useState({sort: 'sortById', query: (params.word || '')})
	const sortedAndSearchedPosts = usePosts(posts, filter.sort, filter.query)
	const [info, setInfo] =useState({
		nickname: nicename || params.name,
		name: '',
		url: '/',
		description: '',
		special: false,
	})
	useEffect ( () => {
		if (!terms.hasOwnProperty('terms')) return
		if (!params.name && !nicename) return
		fetchPosts(info.nickname, filter.query)
	}, [])
	
	const size='3rem'
	const icons = [
		<GiArcingBolt size={size} />,
		<GiAbstract019 size={size} />,
		<GiAbstract093 size={size} />,
		<GiAerodynamicHarpoon size={size} />,
		<GiAfrica size={size} />,
		<GiAirBalloon size={size} />,
		<GiAk47 size={size} />,
		<GiAmmonite size={size} />,
		<GiAngelWings size={size} />,
		<GiAngularSpider size={size} />,
		<GiArmadillo size={size} />,
		<GiAtSea size={size} />,
		<GiAstronautHelmet size={size} />,
		<GiBackPain size={size} />,
		<GiBalloonDog size={size} />,
		<GiBanjo size={size} />,
		<GiBaseballGlove size={size} />,
		<GiBat size={size} />,
		<GiBeamsAura size={size} />,
		<GiBeanstalk size={size} />,
		<GiBearFace size={size} />,
		<GiBigWave size={size} />,
		<GiBison size={size} />,
		<GiBlast size={size} />,
		<GiBlackball size={size} />,
		<GiBirdTwitter size={size} />,
		<GiBrainTentacle size={size} />,
		<GiBurningForest size={size} />,
		<GiCamel size={size} />,
		<GiCargoShip size={size} />,
		<GiCentipede size={size} />,
		<GiChicken size={size} />,
		<GiCleopatra size={size} />,
		<GiCrossedClaws size={size} />,
		<GiCuauhtli size={size} />,
		<GiDiplodocus size={size} />,
		<GiDoubleFish size={size} />,
		<GiEagleEmblem size={size} />,
		<GiExplodingPlanet size={size} />,
		<GiFrozenOrb  size={size} />,
		<GiKiwiBird size={size} />,
		<GiLockedChest size={size} />,
		<GiMoonOrbit size={size} />,
		<GiMushroomGills size={size} />,
		<GiNautilusShell size={size} />,
		<GiWinterGloves size={size} />,
		<GiUnlitBomb size={size} />,
		<GiThrowingBall size={size} />,
	]
	const [fetchPosts, isPageLoading, postError] = useFetching( async (name, query) => {
		let response = await PostService.getByAuthor(name);
		response.data.result.forEach(p => {
			if (terms.postToTermNumber[p.ID]) {
				p.terms = terms.postToTermNumber[p.ID].map (tn => {
					return {
						name: terms.terms[tn]['name'],
						terms_id: terms.terms[tn]['term_id'],
						number: tn,
						slug: terms.terms[tn]['slug'],
						tax: terms.terms[tn]['taxonomy'],
						desc: terms.terms[tn]['description'],
				}})
			} else {
				p.terms = []
			}
		})

		setPosts([...response.data.result.map (p => {return {...p, post_content : query}})])
		const meta = response.data.meta;
		setInfo({
			notation: (meta.first_name ? '' : 'блог '),
			name: (meta.first_name + ' ' + meta.last_name),
			nickname: response.data.author_info.display_name,
			url: response.data.author_info.user_url,
			description: meta.description.replace(/\[/g, '<').replace(/\]/g, '>'),
			special: response.data.special,
		})
	})
	return (
		<div className="">
				<Helmet>
					<title>{info.nickname}</title>
					<meta
					  name="description"
					  content={'блог ' + info.nickname}
					/>
				</Helmet>
				{postError && 
					<h2 style={{color:'red',textAlign:'center'}}>Error occured: ${postError}</h2>
				}
				<div className="col-12 col-md-11 col-lg-10 content m-auto">
					<div className="p-1 mb-4">
						<div className="m-0 p-1">
							<span className="h4 text-muted">блог </span>
							<a href={info.url} className="text-decoration-none" >
							<span className="h2 link-dark text-decoration-none">
								{info.nickname}
							</span>
							</a>
						</div>
						<div className="m-0 p-1">
							{parse(info.description)}
						</div>
					</div>
				</div>
			    <PostFilter 
				    filter={filter}
				    setFilter={setFilter}
			    />
				<PostListFlat 
					posts={sortedAndSearchedPosts} 
					title={''}
					description={''}
					needName={false}
					special={info.special}
					icons={icons}
				/> 
				{isPageLoading && <Loader /> }
		</div>	
	)
}

export default Blog;
