import {defaultState, termActions} from '../vars/term'

export const termReducer = (state = defaultState, action ) => {
	switch (action.type)  {
		case termActions.fetchTerms:
			return {...state, loading: true}
		case termActions.fetchTermsSuccess:
			return {...state, loading: false, terms: action.terms}
		case termActions.fetchTermsError:
			return {...state, loading: false, error: action.error}
		default:
			return state
	}
}
