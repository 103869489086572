import React, {useContext} from 'react';
import MyInput from '../components/UI/input/MyInput'
import MyButton from '../components/UI/button/MyButton'
import {AuthContext} from '../context';

const Login = () => {
	const {isAuth, setIsAuth} = useContext(AuthContext);
	const login = (e) => {
		e.preventDefault();
		setIsAuth(true);
		console.log('isAuth', isAuth)
	}

	return (
		<div>
			<h1>Authorization page</h1>
			<form onSubmit={login}>
				<MyInput type="text" placeholder="login"/>
				<MyInput type="text" placeholder="password"/>
				<MyButton>Enter</MyButton>
			</form>
		</div>
	)
}

export default Login;
