export const defaultState = {
	loading: true,
	terms: [],
	error: null,
}


export const termActions = {
	fetchTerms: 'FETCH_TERMS',
	fetchTermsSuccess: 'FETCH_TERMS_SUCCESS',
	fetchTermsError: 'FETCH_TERMS_ERROR',
}
